import React from 'react'
import styled from 'styled-components'
import Clickbait from '../assets/clickbait.png'
import ActiveLearning from '../assets/student-raise-hand.png'
import Discussion from '../assets/discussion.png'
import VotesFilter from '../assets/voteFilter.png'



const SectionBackground = styled.div`
    background-color: rgba(236, 236, 236, 0.4);
    width:100%;
    `
const WhyUpgradeContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    @media (max-width: 804px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }
    @media (max-width: 600px) {
        padding: 10px;
    }

`
const AdvantagesDisplay = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: unset;
    @media (max-width:804px){
        justify-self: center;
    }

    @media (max-width: 804px) {
        width: 100%;
    }
`
const GridCell = styled.div`
    display: grid;
    grid-template-rows: 3fr 1fr;
    align-items: center;
    justify-content: center;
    grid-auto-flow: column;
    margin: 10px;
    text-align: center;
    @media (max-width: 804px) {
    }
`


const TextContainer = styled.div`
    @media (max-width: 804px) {
        grid-row: 1 / 1;
        justify-self: center;
    }
    margin: 20px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 804px) {
        font-size: 18px;
        text-align: center;
    }
    justify-self: start;
`

const CustomImgTag = styled.img`
    @media (max-width: 804px) {
            width: 80%;
            min-width: 200px;
    }
    @media (max-width: 620px) {
        width: 100px;
        min-width: 0;
    }
`;

export const WhyUpgrade = () => {
    return (
        <SectionBackground>
            <WhyUpgradeContainer>
                <AdvantagesDisplay>
                    <GridCell>
                        <CustomImgTag src={Clickbait} alt='Clickbait icon' />
                        <p>Hide distractions</p>
                    </GridCell>
                    <GridCell>
                        <CustomImgTag src={ActiveLearning} alt='Student raising hand' />
                        <p>Active learning</p>
                    </GridCell>
                    <GridCell>
                        <CustomImgTag src={Discussion} alt='Discussion icon' />
                        <p>Join your community</p>
                    </GridCell>
                    <GridCell>
                        <CustomImgTag src={VotesFilter} alt='Filtered by votes' />
                        <p>Filtered by votes</p>
                    </GridCell>
                </AdvantagesDisplay>

                <TextContainer>
                    <h1>Why upgrade YouTube?</h1>
                    <p>Everyone knows you can learn anything on YouTube, but if you’ve tried it, you’ll know it’s not as easy as it sounds. </p>
                    <p>Currently the site is optimised for more clicks and passive watching. This doesn’t make learning easy.</p>
                    <p>To really understand a topic we need to engage with content, ask/answer questions about it, and actively learn. Right now YouTube isn’t built for that... </p>
                    <p>So that’s where ScholarMode comes in.</p>
                </TextContainer>
            </WhyUpgradeContainer>
        </SectionBackground>
    )
}


