import React from 'react'
import { Footer } from '../sections/Footer';
import { GetStarted } from '../sections/GetStarted';
import { HeroSection } from '../sections/HeroSection';
import { Navbar } from '../sections/Navbar';
import { WhyUpgrade } from '../sections/WhyUpgrade';
import { BugReport } from '../sections/BugReport';

export const Home = () => {
    return (
        <div id='outer-container'>
            <Navbar />
            <div id='page-wrap'>
                <HeroSection />
                <WhyUpgrade />
                <GetStarted />
                <BugReport />
                <Footer />
            </div>
        </div>
    )
}
