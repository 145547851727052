import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { useEffect, useState, useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        marginTop: theme.spacing(1.5),
        minWidth: 220,
    },
}));

function SelectBugType({ bug, setBug }) {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        setBug(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const labelRef = useRef()
    const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0
    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label" placeholder="Type of report">Report Type</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={bug}
                    onChange={handleChange}
                    label="Age"
                    autoWidth={true}
                >
                    <MenuItem value="Bug Report">Bug Report</MenuItem>
                    <MenuItem value="Feature Request">Feature Request</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default SelectBugType
