import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
    padding: 10vw;
`
const ThinHeading = styled.h1`
    font-weight: 400;

`
const HighlightedTip = styled.h2`
    font-weight: 700;
`
const ListOfLinks = styled.ul`
`
const ItemLinks = styled.a`
    color: var(--highlight-blue);

`

export const ErrorSection = () => {
    return (
        <ErrorContainer>
            <ThinHeading>Error 404 - Page not found</ThinHeading>
            <HighlightedTip>Hmm... maybe you were looking for these?</HighlightedTip>
            <ListOfLinks>
                <li><ItemLinks href='/'>scholarmode.com</ItemLinks></li>
                <li><ItemLinks href='/privacypolicy'>scholarmode.com/privacypolicy</ItemLinks></li>
            </ListOfLinks>
        </ErrorContainer>
    )
}
