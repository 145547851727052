import firebase from '@firebase/app'
import '@firebase/storage'


var firebaseConfig = {
    apiKey: "AIzaSyB3GekapL_IvmVgARGoceFpefsfVe2oZyo",
    authDomain: "scholarmode-aba6e.firebaseapp.com",
    projectId: "scholarmode-aba6e",
    storageBucket: "scholarmode-aba6e.appspot.com",
    messagingSenderId: "866146288725",
    appId: "1:866146288725:web:0cc4d7b4ab128bdabdc0dc",
    measurementId: "G-MPTVGS9DZ4"
};

if (!firebase.apps?.length) {
    firebase.initializeApp(firebaseConfig)
}

export default firebase

