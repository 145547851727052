import './App.css';
import { Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home'
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
import { Error } from './pages/Error'

function App() {
  return (
	<Switch>
		<Route path='/' exact component={Home} /> 
		<Route path='/privacypolicy' component={PrivacyPolicy} />  
		<Route path='/termsofservice' component={TermsOfService} />
		<Route component={Error} />   
	</Switch>
  );
}

export default App;
