import React, {useState} from 'react'
import { ReactComponent as Logo } from '../assets/scholarHatLogo.svg';
import styled from 'styled-components'
import {push as Menu } from 'react-burger-menu'



const Nav = styled.div`
    display: flex;
    flex-direction: row;
    background-color: var(--grey1);
    width: 100vw;
    /* position: sticky; */
`

const LogoContainer = styled.a`
    padding: 5px;
    margin-left: 10px;
`

const NavLinkContainer = styled.div`
    display: flex;
    height: auto;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
    
`

const NavLink = styled.a`
    font-size: 18px;
    padding: 10px;
    color: black;
    text-decoration: none;
    &:hover{
        color: var(--grey4);
    }
    @media (max-width:804px) {
        display: none
    }
`
const MenuContainer = styled.div`
    display:none;
    @media (max-width:804px) {
        display: flex;
        /* margin-left:auto; */
    }
`
const MenuNavLink = styled.a`
    font-size: 18px;
    padding: 10px;
    color: var(--grey4);
    text-decoration: none;
    &:hover{
        color: white;
    }
`

var styles = {
    bmBurgerButton: {
      position: 'relative',
      width: '36px',
      height: '30px',
      float: 'right',
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      position: 'relative',
      top: '-30px',
      overflow: 'hidden',
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }



export const Navbar = () => {
    const [navOpen, setNavOpen] = useState(false)

    

    return (
        <Nav id = 'nav'>
            <LogoContainer href='/'>
                <Logo style={{ width: '70px' }} />
            </LogoContainer>
            <NavLinkContainer>
                <NavLink href='/#home'>Home</NavLink>
                <NavLink href='/#how-it-works'>How it works</NavLink>
                <NavLink href='/#bug-report'>Found a bug?</NavLink>
                <NavLink href='/#contact-us'>Contact us</NavLink>
                <MenuContainer>
                    <Menu outerContainerId={ "outer-container" } pageWrapId={ "page-wrap" } styles={styles} right >
                        <MenuNavLink href='/#home'>Home</MenuNavLink>
                        <MenuNavLink href='/#how-it-works'>How it works</MenuNavLink>
                        <MenuNavLink href='/#bug-report'>Found a bug?</MenuNavLink>
                        <MenuNavLink href='/#contact-us'>Contact us</MenuNavLink>
                    </Menu>
                </MenuContainer>
            </NavLinkContainer>
            
        </Nav>
    )
}
