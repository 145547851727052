import React from 'react'
import styled from 'styled-components'
import authTut from '../assets/authTut.gif'
import discussionTut from '../assets/discussionTut.gif'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 85px;
`
const GetStartedContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    @media (max-width: 850px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
    }
    margin: 10px;
`
const CustomTextContainer = styled.div`
    @media (max-width: 850px) {
        grid-row: 1 / 1;
    }
    max-width:300px;
    margin: 0px 20px;
    align-items: center;
    justify-content: center;
    p{
        margin: 0px;
    } 
    h1{
        margin:0px;
        text-align:center;
    }
`
const LinkSpan = styled.a`
    /* color: var(--highlight-blue); */
    
`

const HowItWorksContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    @media (max-width: 850px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 0px;
    }
    margin: 10px;
    margin-top: 80px;
`

const Title = styled.h1`
    font-size: 2.5em;
`

const AuthTut = styled.img`
    max-width: 500px;
    @media (max-width:550px){
        max-width: 300px;
    }
`
const DiscussionTut = styled.img`
    max-width: 500px;
    @media (max-width:550px){
        max-width: 300px;
    }
`

export const GetStarted = () => {
    return (
        <Container id='how-it-works'>
            <Title>How to get started</Title>
            <GetStartedContainer>
                <CustomTextContainer>
                    <p>After you’ve downloaded the extension from the <LinkSpan href='#'>chrome web store</LinkSpan></p>
                    <ol>
                        <li><p>Log in to your Google account in the browser</p></li>
                        <li><p>Go to <LinkSpan href='#'>youtube.com</LinkSpan> and accept the popup window</p></li>
                        <li><p>And now you’re ready to use ScholarMode.</p></li>
                    </ol>
                </CustomTextContainer>
                <AuthTut src={authTut} alt='Authorisation Tutorial'/>
            </GetStartedContainer>
            <HowItWorksContainer>
                <DiscussionTut src={discussionTut} alt='Discussion Tutorial'/>
                <CustomTextContainer>
                    <h1>How it works</h1>
                    <ul>
                        <li><p>Turn on ScholarMode to ask a question or reply to others</p></li>
                        <li><p>Vote on the best posts, this updates every video with top voted questions & replies</p></li>
                    </ul>
                </CustomTextContainer>
            </HowItWorksContainer>
        </Container>
    )
}
