import React from 'react'
import styled from 'styled-components'
import sloganLogo from '../assets/ScholarModeBrandSloganLogo.svg'
import { Link } from 'react-router-dom'




const FooterContainer = styled.div`
    position: relative;
    display: flex;
    overflow:hidden;
    min-height: 300px;
    background-color: black;
    align-items: center;
    justify-content: center;
`
const BackgroundDiv = styled.div`
    background-color: black;
    transform: rotate(2deg);
    width: 120vw;
    height: 500px;
    position: relative;
    top:-70px;
    z-index: 0;
    /* margin-top: auto; */
    /* bottom: -100px; */
`
const FooterContents = styled.div`
    margin: 5vw;
    margin-top: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    z-index:10;
    height:auto;
    width: 90vw;
    align-items:center;
    justify-content: space-between;
    @media (max-width: 768px) {
        width: auto;
        flex-direction: column;
        align-items: flex-start;
    }

`

const LogoContainer = styled.div`
    max-width: 700px;
`

const NavMenuContainer = styled.div`
    display: flex;
    @media (max-width:768px) {
        flex-direction: column;
    }
`
const NavMenu = styled.div`
    padding: 10px;
    @media (max-width:768px) {
        padding: 0px;
    }
`

const CustomWhiteHeading = styled.h1`
    color: var(--grey1);
    font-size: 24px;
`
const CustomWhiteText = styled.p`
    color: var(--grey2);
    margin: 0;
`
const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const scrollToTopOfPage = () => {
    window.scrollTo(0,0);
}

export const Footer = () => {
    const footerstyle = {
		color: 'var(--grey2)',
        textDecoration:"none"
	}
    

    return (
        <>
            <FooterContainer id='contact-us'>
                {/* <BackgroundDiv /> */}
                <FooterContents>
                    <LogoContainer>
                        <img src={sloganLogo} style={{width: "300px", position:'relative', left:'-25px'}} alt='ScholarMode, level up your learning' />
                    </LogoContainer>
                    <NavMenuContainer>
                        <NavMenu>
                            <CustomWhiteHeading>Legal stuff</CustomWhiteHeading>
                            <LinkContainer>
                                <Link style={footerstyle} to='/privacypolicy' onClick={scrollToTopOfPage}>Privacy Policy</Link>
                                <Link style={footerstyle} to='/termsofservice' onClick={scrollToTopOfPage}>Terms of Service</Link>
                            </LinkContainer>
                        </NavMenu>
                        <NavMenu>
                            <CustomWhiteHeading>Contact us</CustomWhiteHeading>
                            <CustomWhiteText>info@scholarmode.com</CustomWhiteText>
                            <CustomWhiteText>+(61) 467531126</CustomWhiteText>
                        </NavMenu>
                    </NavMenuContainer>
                </FooterContents>
            </FooterContainer>
        </>
    )
}
