import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { ReactComponent as YTDisplay} from '../assets/ytViewportBackground.svg'
import { ReactComponent as Videos} from '../assets/recomendedVideosWireframe.svg'
import { ReactComponent as ToggleBackground} from '../assets/ScholarButtonBackgroundLightmode.svg'
import { ReactComponent as ToggleCircle} from '../assets/ScholarButtonCircleLightmode.svg'
import { ReactComponent as MockQ1} from '../assets/mockQuestion1.svg'
import { ReactComponent as MockQ2} from '../assets/mockQuestion2.svg'
import { ReactComponent as MockQ3} from '../assets/mockQuestion3.svg'

// Text
const HeroContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
`
const HeroValueProp = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding-left: 100px;
    @media (max-width: 1100px) {
        padding: 50px;
        justify-self: center;
    }
`
const CTA = styled.button`
    padding: 10px;
    max-width: 200px;
    margin-right: auto;
`
const BetaTag = styled.span`
    /* position: absolute;
    top: 110px; 
    align-self: flex-end; */
    font-weight: 400;
    font-size: 16px;
    transform: translate(-8px, -19px) rotate(20deg);
    display: inline-block;
`
const TagContainer = styled.span`
    display: inline-block;
`
const CustomH1 = styled.h1`
        /* @media (max-width: 804px) {
            font-size: 20px;
    } */
`;
const CustomH2 = styled.h2`
            /* @media (max-width: 804px) {
            font-size: 20px;
    } */
`;


// Animation
const YoutubeMockDisplay = styled.div`
    margin: 5vw;
    position: relative;
    justify-self: start;
    @media (max-width: 1100px) {
        justify-self: center;
    };
    @media (max-width:580px){
        width: 360px;
    }
    @media (max-width:375px){
        width: 300px;
    }
`
const VideosContainer = styled.div`
    position: absolute;
    bottom: 0px; right:10px;
    @media (max-width: 580px) {
        width: 30.7%;
        bottom: 11px;
    }
`
const MyYTDisplay = styled(YTDisplay)`
    @media (max-width: 580px) {
        width: 100%;
    }
`
const FadeOut = keyframes`
    0% {
        opacity: 0;
    }
`
const RecVideos = styled(Videos)`
    animation-duration: 1s;
    animation-name: ${ FadeOut };
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.active ? "normal" : "reverse" ) };
    animation-delay: 2s ;
    @media (max-width:580px) {
        width: 100%;
    }
    
`
const FadeIn = keyframes`
    0%{
        opacity: 1
    }
`
const DiscussionContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top:58px; right:20px;
    width:30%;
    @media (max-width: 580px) {
        top:102px; right: 14px;
        width:29%;
    }
    @media (max-width: 375px) {
        top: 110px;
    }
`
const Q1 = styled(MockQ1)`
    width: 100%;
    height: 100%;
    padding-bottom: 8px;
    opacity: 0;
    animation-duration: 1s;
    animation-name: ${ FadeIn };
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.active ? "normal" : "reverse" ) };
    animation-delay: 0s ;
    @media (max-width: 580px) {
        padding-bottom: 5px;
    }
`
const Q2 = styled(MockQ2)`
    width: 100%;
    height: 100%;
    padding-bottom: 8px;
    opacity: 0;
    animation-duration: 1s;
    animation-name: ${ FadeIn };
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.active ? "normal" : "reverse" ) };
    animation-delay: 1s ;
    @media (max-width: 580px) {
        padding-bottom: 5px;
    }
`
const Q3 = styled(MockQ3)`
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-duration: 1s;
    animation-name: ${ FadeIn };
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.active ? "normal" : "reverse" ) };
    animation-delay: 2s ;
`



const ToggleContainer = styled.div`
    position: absolute;
    /* cursor: pointer; */
    top: 3.3%; right:23.5%;
    @media (max-width: 580px) {
        top:18.1%; right: 17%;
    }
    @media (max-width: 375px) {
        top: 23%; right: 14%;
    }
`
const ToggleAnimOn = keyframes`
    0%{
        color: var(--red);
    }
`
const ToggleBg = styled(ToggleBackground)`
    z-index: 0;
    color: var(--white);
    animation-duration: 0.5s;
    animation-name: ${ ToggleAnimOn };
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.active ? "normal" : "reverse" ) };
    animation-delay: 1.5s ;
    @media (max-width: 580px) {
        width: 75%;
    }
    @media (max-width: 375px) {
        width: 60%;
    }
`

const CircAnimOn = keyframes`
    0% {
        transform: translate(35px);
    }
`
const CircAnimOn580px = keyframes`
    0% {
        transform: translate(26px);
    }
`
const CircAnimOn375px = keyframes`
    0% {
        transform: translate(21px);
    }
`
const ToggleCirc = styled(ToggleCircle)`
    position: absolute;
    bottom: 22.3%; left: 4.5%;
    animation-duration: 0.5s;
    animation-name: ${ CircAnimOn };
    animation-fill-mode: forwards;
    animation-direction: ${(props) => (props.active ? "normal" : "reverse" ) };
    animation-delay: 1.5s ;
    z-index: 10;
    @media (max-width: 580px) {
        width: 28%;
        left: 3.8%;
        bottom: 21%;
        animation-name: ${ CircAnimOn580px };
    }
    @media (max-width: 375px) {
        width: 22.5%;
        left: 3%;
        bottom: 23%;
        animation-name: ${ CircAnimOn375px };
    }
`




export const HeroSection = () => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        setTimeout(()=>{setActive(true)},3000)


    }, [])

    return (
        <HeroContainer id='home'>
            <HeroValueProp>
                <CustomH1>Level up YouTube with <TagContainer> ScholarMode.<BetaTag>Beta!</BetaTag> </TagContainer></CustomH1>
                <CustomH2>A tool for students to learn better through video. </CustomH2>
                <CTA onClick={()=>window.location.href='https://chrome.google.com/webstore/detail/scholarmode/heonechjlniccepejmajdmaokjocphoe'}>Upgrade now</CTA>
            </HeroValueProp>

            <YoutubeMockDisplay id='YT-animation'>
                <MyYTDisplay />
                <ToggleContainer active={active} /*onClick={() => {setActive(!active)}}*/>
                    <ToggleCirc />
                    <ToggleBg />
                </ToggleContainer>
                    {!active 
                        ? <VideosContainer> 
                            <RecVideos />
                        </VideosContainer>

                        : <DiscussionContainer> 
                            <Q1 /> <Q2 /> <Q3 /> 
                        </DiscussionContainer>
                    }
                
            </YoutubeMockDisplay>
        </HeroContainer>
    )
}
