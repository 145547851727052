import React from 'react'
import { Footer } from '../sections/Footer';
import { Terms } from '../sections/Terms';
import { Navbar } from '../sections/Navbar';


export const TermsOfService = () => {
    return (
        <div>
            <Navbar />
            <Terms />
            <Footer />
        </div>
    )
}
