import React from 'react'
import { Footer } from '../sections/Footer';
import { Privacy } from '../sections/Privacy';
import { Navbar } from '../sections/Navbar';

export const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar />
            <Privacy />
            <Footer />
        </div>
    )
}
