import { CircularProgress } from '@material-ui/core';
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components'
import { uploadFromBlobAsync } from '../storage'
import Alert from '@material-ui/lab/Alert';


const CustomFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #dadada;
    width: 150px;
    height: 150px;
    padding: 50px;
    border-radius: 5px;
    margin-top: 5px;
`;




function ImageDropzone({ setImageUrl }) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)
    const onDrop = useCallback(async (acceptedFiles) => {
        console.log("Inside")
        const file = acceptedFiles?.[0]

        if (!file) {
            return
        }

        setIsLoading(true)
        setError(null)
        setMessage(null)

        try {
            const urlVal = await uploadFromBlobAsync({
                blobUrl: URL.createObjectURL(file),
                name: `${file.name}_${Date.now()}`,
            })
            setImageUrl(urlVal)
        } catch (e) {
            setIsLoading(false)
            setError(e)
            console.log(e)
        }
        setIsLoading(false)
        setMessage('File was uploaded 👍')
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <>
            {console.log("Here 1")}
            <CustomFlex {...getRootProps()}>
                {console.log(isDragActive)}
                <input {...getInputProps()} />
                {isLoading ? (
                    <CircularProgress />
                ) : isDragActive ? (
                    <p>Drop the files here...</p>
                ) : (
                    <p>Drag 'n' drop some files here, or click to select files</p>
                )}
            </CustomFlex>
            {(error || message) && (
                <Alert
                    severity={error ? 'error' : 'success'}
                    style={{
                        width: '218px',
                        borderRadius: '5px',
                        marginTop: '5px',
                    }}
                >
                    {error || message}
                </Alert>
            )}
        </>
    )
}

export default ImageDropzone
