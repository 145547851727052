import React from 'react'
import { Navbar } from '../sections/Navbar';
import { ErrorSection } from '../sections/ErrorSection';
import { Footer } from '../sections/Footer';

export const Error = () => {
    return (
        <div id='outer-container'>
            <Navbar />
            <ErrorSection />
            <Footer />
        </div>
    )
}
