import styled from 'styled-components'
import { CircularProgress, TextField } from '@material-ui/core';
import SelectBugType from './SelectBugType';
import AttachScreenshot from './AttachScreenshot';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import ImageDropzone from './ImageDropzone';

const InputBugDiv = styled.div`
    width: 80%;
    max-width: 500px;
    margin-left: 20px;
    margin-top: 50px;
    justify-self: start;
    @media (max-width: 804px) {
        justify-self: center;
        margin-left: 0px;
    }
`;

const CustomInputForm = styled.form`
`;

const ColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
`;

const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

const SubmitBugButton = styled.button`
    margin-top: 10px;
    padding: 10px;
    width: 100px;
    border: none;
    cursor: pointer;
`;

const ResponsiveRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 804px) {
        flex-direction: column;
        margin-left: 0px;
        text-align: initial;
    }
`;
const SuccessMessage = styled.p`
    color: darkgreen;
`




export const InputBugDetails = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [title, setTitle] = useState('')
    const [issue, setIssue] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [submitLoading, setSubmitLoading] = useState(false)
    const [bug, setBug] = useState('');
    const [submitted, setSubmitted] = useState(false)

    const [state, setState] = useState({
        screenshot: false,
    });

    const handleChange = (event) => {
        event.target.name==="name" 
        ? setName(event.target.value)
        : event.target.name==="email" 
        ? setEmail(event.target.value)
        : event.target.name==="title" 
        ? setTitle(event.target.value)
        : event.target.name==="issue"
        ? setIssue(event.target.value)
        : console.log("error")
      };

    var templateParams = {
        user_name: name,
        user_email: email,
        user_title: title,
        user_issue: issue,
        user_image_url: imageUrl,
        report_type: bug,
    };

    function sendEmail(e) {
        e.preventDefault();
        setSubmitLoading(true)
        emailjs.send('service_rvd3wo8', 'template_9ae76qc', templateParams, 'user_9xhRGMDsuhX2kBYtzxGbq')
            .then((result) => {
                console.log(result.text);
                setSubmitLoading(false)
                setName("")
                setEmail("")
                setTitle("")
                setIssue("")
                setImageUrl("")
                setBug("")
                setState({
                    screenshot: false,
                })
                setSubmitted(true)
                setTimeout(()=>{setSubmitted(false)}, 5000)
            }, (error) => {
                console.log(error.text);
                setSubmitLoading(false)
            });
    }


    return (
        <InputBugDiv>
            <CustomInputForm noValidate autoComplete="off" onSubmit={sendEmail}>
                <RowDiv>
                    <TextField 
                        style={{ marginRight: '10px', }} 
                        className="outlined-basic" 
                        label="Name" 
                        variant="outlined" 
                        name="name" 
                        onChange={(e) => handleChange(e)}
                        value={name} />
                    <TextField 
                        style={{ marginRight: '10px', width: '300px' }} 
                        className="outlined-basic" 
                        label="Email" 
                        variant="outlined"
                        name="email" 
                        onChange={(e) => handleChange(e)} 
                        value={email}/>
                </RowDiv>
                <RowDiv>
                    <TextField 
                        style={{ marginRight: '10px', width: '100%', marginTop: '10px' }} 
                        className="outlined-basic" 
                        label="Title" 
                        variant="outlined" 
                        name="title"
                        onChange={(e) => handleChange(e)} 
                        value={title}/>
                </RowDiv>

                <RowDiv>
                    <TextField 
                        multiline={true} 
                        minRows={9} 
                        style={{ marginRight: '10px', width: '100%', marginTop: '10px' }} 
                        className="outlined-basic" 
                        label="Explain your issue..." 
                        variant="outlined" 
                        name="issue"
                        onChange={(e) => handleChange(e)} 
                        value={issue}/>
                </RowDiv>
                {/* Selection Goes Here */}
                <ResponsiveRowDiv>
                    <SelectBugType 
                        bug={bug} 
                        setBug={setBug} />
                    <AttachScreenshot 
                        state={state} 
                        setState={setState} />
                </ResponsiveRowDiv>

                {state.screenshot && <ImageDropzone setImageUrl={setImageUrl} />}
                {
                    submitLoading ?
                        <SubmitBugButton type="submit" value="Send" ><CircularProgress size={20} color="secondary" /></SubmitBugButton> 
                            :
                        <SubmitBugButton type="submit" value="Send" >Submit</SubmitBugButton>

                }
                {
                    submitted ?
                    <SuccessMessage id='success'>Request sent successfully 🙌</SuccessMessage>
                        :
                    null
                }

            </CustomInputForm>
        </InputBugDiv>
    )
}