import styled from 'styled-components'
// import { makeStyles } from '@material-ui/core/styles';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect, useState } from 'react';


const ScreenshotDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-left: 40px;
    @media (max-width: 804px) {
        justify-content: end;
        margin-left: 0px;
    }
`;

function AttachScreenshot({ state, setState }) {
    // const [state, setState] = useState({
    //     screenshot: false,
    // });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const { screenshot } = state;
    const error = [screenshot].filter((v) => v).length !== 2;
    return (
        <ScreenshotDiv>
            <FormControl component="fieldset" >
                <FormGroup>
                    <FormControlLabel
                        style={{ padding: '0px' }}
                        control={<Checkbox checked={screenshot} onChange={handleChange} name="screenshot" />}
                        label="Attach Screenshot"
                    />
                </FormGroup>
            </FormControl>
        </ScreenshotDiv>
    )
}

export default AttachScreenshot
