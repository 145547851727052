import styled from 'styled-components'
import { InputBugDetails } from '../components/InputBugDetails'

const BugDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    @media (max-width: 784px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        text-align: center;
    }
    padding-bottom: 85px;
`;

const TipsText = styled.h1`
    padding-top: 10px;
    font-size: 24px;
`;

const CustomListTag = styled.li`
    margin-left: -20px;
`;


const LeftColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-self: end;
    margin-right: 100px;
    @media (max-width: 824px) {
        justify-self: center;
        margin-right: 0px;
    }
    @media (max-width: 384px) {
        width: 220px;
    }
`;

export const BugReport = () => {
    return (
        <BugDiv id='bug-report'>
            <LeftColumnDiv>
                <h1> Found a bug ? </h1>
                <p>
                    Help shape the platform by letting us know <br />
                    what's broken and how it could be improved.
                </p>

                <TipsText>Tips for bug reports</TipsText>
                <ul>
                    <CustomListTag>Describe the problem</CustomListTag>
                    <CustomListTag>Send us a screenshot or video</CustomListTag>
                    <CustomListTag>What browser are you using? <br /> (eg: Chrome 88.0)</CustomListTag>
                    <CustomListTag>Which version of ScholarMode: 0.1.0</CustomListTag>
                </ul>

                <TipsText>Tips for feature requests</TipsText>
                <p>
                    ScholarMode is in Beta, so every piece of <br />
                    feedback we get has the chance to shape <br />
                    it’s future.
                </p>
                <p>
                    If you want to be apart of the extension’s <br />
                    direction, send us a feature request and we’ll <br />
                    discuss this further
                </p>
            </LeftColumnDiv>
            <InputBugDetails />

        </BugDiv>
    )
}
